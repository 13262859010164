import React from 'react';

import Header from '../components/Header';
import LoginForm from '../components/LoginForm';

function Activate() {
    return (
        <>
            <Header backTo="/" />
            <h1 className="page-title">Activate your stay</h1>
            <LoginForm></LoginForm>
        </>
    );
}

export default Activate;