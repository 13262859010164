import React from 'react';
import { Link } from 'react-router-dom';

import smallLogo from '../logo-small.png';

function Header(props) {
    return (
        <header className="header">
            <Link className="back-link" to={ props.backTo }>
                <svg className="back-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M231.536 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L60.113 273H436c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H60.113L238.607 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"></path></svg>
            </Link>
            <img src={ smallLogo } className="logo" alt="Hotel Central SB" />
            <span></span>
        </header>
    );
}

export default Header;