import React from 'react';
import { connect } from 'react-redux';
import Spinner from '../components/Spinner';
import Header from '../components/Header';
import Address from '../components/Address';
import Location from '../components/Location';

import { fetchAllPois } from '../actions/pois';

class Poi extends React.Component {
    componentDidMount() {
        const { fetchPois } = this.props;

        fetchPois();
    }

    render() {
        const { poi, loading } = this.props;

        if (loading) {
            return (
                <>
                    <Header backTo="/guide/" />
                    <Spinner />
                </>
            );
        }

        if ( ! poi) {
            return null;
        }

        return (
            <>
                <Header backTo="/guide/" />
                <h1 className="page-title">{ poi.name.en }</h1>
                { poi.image && <div className="event-image"><img src={ poi.image } alt={ poi.name.en } /></div> }
                <div className="page-content mt-4 mb-8" dangerouslySetInnerHTML={{ __html: poi.content.en }}></div>
                { poi.url && <div className="event-url"><a href={ poi.url } target="_blank" rel="noopener noreferrer">{ poi.url }</a></div> }
                <Address { ...poi } />
                <Location { ...poi } />
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({
    poi: state.pois.items.find(poi => poi.id === parseInt(props.match.params.id, 10)),
    loading: state.pois.loading,
    error: state.pois.error
});

const mapDispatchToProps = (dispatch) => ({
    fetchPois: () => {
        dispatch(fetchAllPois())
    }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Poi);