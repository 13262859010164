import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Spinner from '../components/Spinner';
import Header from '../components/Header';

import format from 'date-fns/format';

import { fetchFutureEvents } from '../actions/events';

function Event(props) {
    const { event, index } = props;

    let classes = 'event__item';

    switch (index % 2) {
        default:
        case 0:
            break;
        case 1:
            classes += ' event__item--light';
            break;
        case 2:
            classes += ' event__item--lighter';
            break;
    }

    return (
        <div className={ classes } key={ event.id }>
            <Link className="event__item__link" to={ '/events/' + event.id }>
                <h2>{ event.name.en }</h2>
                <div className="event__item__dates">
                    <span>Starts <span className="date">{ format(event.starts_at.date, 'ddd, MMM DD. HH:mm') }</span></span>
                    <span>Ends <span className="date">{ format(event.ends_at.date, 'ddd, MMM DD. HH:mm') }</span></span>
                </div>
            </Link>
        </div>
    );
}

class Events extends React.Component {

    componentDidMount() {
        const { fetchEvents } = this.props;

        fetchEvents();
    }

    render() {
        const { events, loading } = this.props;

        if (loading) {
            return (
                <>
                    <Header backTo="/" />
                    <h1 className="page-title">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="page-title__icon"><path fill="currentColor" d="M400 64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H128V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h352c8.8 0 16 7.2 16 16v48H32v-48c0-8.8 7.2-16 16-16zm352 384H48c-8.8 0-16-7.2-16-16V192h384v272c0 8.8-7.2 16-16 16zM148 320h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 96h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm192 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12z"></path></svg>
                        <span className="page-title__label">Events</span>
                    </h1>
                    <Spinner />
                </>
            );
        }

        if (events.length === 0) {
            return null;
        }

        return (
            <>
                <Header backTo="/" />
                <h1 className="page-title">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="page-title__icon"><path fill="currentColor" d="M400 64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H128V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h352c8.8 0 16 7.2 16 16v48H32v-48c0-8.8 7.2-16 16-16zm352 384H48c-8.8 0-16-7.2-16-16V192h384v272c0 8.8-7.2 16-16 16zM148 320h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 96h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm192 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12z"></path></svg>
                    <span className="page-title__label">Events</span>
                </h1>
                <div className="events">
                    {
                        events.map((event, index) => {
                            return (
                                <Event key={ event.id } event={ event } index={ index} />
                            );
                        })
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    events: state.events.future,
    loading: state.events.loading,
    error: state.events.error
});

const mapDispatchToProps = (dispatch) => ({
    fetchEvents: () => {
        dispatch(fetchFutureEvents())
    }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Events);