import { RECEIVE_CURRENT_WEATHER, RECEIVE_WEATHER_FORECAST, WEATHER_FETCHING_FAILED, START_FETCHING_WEATHER } from '../actions/weather';

export const weather = (state = {
    current: null,
    forecast: [],
    loading: false,
    error: false
}, action) => {
    switch (action.type) {
        case RECEIVE_CURRENT_WEATHER:
            return Object.assign({}, state, {
                current: action.current,
                loading: false,
                error: false
            });
        case RECEIVE_WEATHER_FORECAST:
            return Object.assign({}, state, {
                forecast: action.forecast,
                loading: false,
                error: false
            });
        case WEATHER_FETCHING_FAILED:
            return Object.assign({}, state, {
                current: null,
                forecast: [],
                error: true,
                loading: false
            });
        case START_FETCHING_WEATHER:
            return Object.assign({}, state, {
                loading: true
            });
        default:
            return Object.assign({}, state);
    }
}