import React from 'react';
import { connect } from 'react-redux';
import WeatherIcon from './WeatherIcon';
import Spinner from './Spinner';
import { Link } from 'react-router-dom';

import { fetchCurrentWeather } from '../actions/weather';

class CurrentWeather extends React.Component {
    componentDidMount() {
        const { fetchCurrent } = this.props;

        fetchCurrent();
    }

    render() {
        const { current, loading, error } = this.props;

        if (error) {
            return null;
        }

        if (loading) {
            return (
                <Spinner />
            );
        }

        if ( ! current) {
            return null;
        }

        return (

            <Link className="weather-widget" to="/weather/">
                <h1 className="text-center text-3xl text-80 font-light">Slavonski Brod</h1>
                <div className="flex mt-2">
                    <div className="w-1/2 text-center self-center">
                        <WeatherIcon icon={ current.icon } />
                    </div>
                    <div className="w-1/2 text-center">
                        <div className="text-3xl">{ current.temp }&deg;</div>
                        <div className="font-light text-sm">{ current.description }</div>
                    </div>
                </div>
                <div className="flex mt-2">
                    <div className="w-1/2 text-center text-xs text-80">
                        Min: { current.min }&deg;
                    </div>
                    <div className="w-1/2 text-center text-xs text-80 font-light">
                        Max: { current.max }&deg;
                    </div>
                </div>
            </Link>
        );
    }
}

const mapStateToProps = (state) => ({
    current: state.weather.current,
    loading: state.weather.loading,
    error: state.weather.error
});

const mapDispatchToProps = (dispatch) => ({
    fetchCurrent: () => {
        dispatch(fetchCurrentWeather())
    }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentWeather);