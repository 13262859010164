import React from 'react';

import ActivateButton from '../components/ActivateButton';
import Header from '../components/Header';

function Wallet() {
    return (
        <>
            <Header backTo="/" />
            <h1 className="page-title">Wallet</h1>
            <div className="page-content mt-4 mb-8">
                <p>In order to access Digital Wallet, activate your stay.</p>
                <div className="mt-4 text-center w-1/4">
                    <ActivateButton></ActivateButton>
                </div>
            </div>
        </>
    );
}

export default Wallet;