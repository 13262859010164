import React from 'react';
import { Link } from 'react-router-dom';

function CardLink(props) {
    if (props.path !== undefined) {
        return <Link to={ props.path } className="block mt-1">{ props.children }</Link>;
    }

    if (props.url !== undefined) {
        return <a href={ props.url } className="block mt-1">{ props.children }</a>;
    }

    return <div className="mt-1">{ props.children }</div>
}

function Card(props) {
    return (
        <CardLink { ...props }>
            <div className="card">
                { props.image ? <img src={ props.image } className="card__img" alt={ props.label } /> : null }
                <div className="card__content">
                    <div className="card__label">
                        { props.icon }
                        <span className="card__title">{ props.label }</span>
                    </div>
                    { props.description ? <div className="card__text">{ props.description }</div> : null }
                </div>
            </div>
        </CardLink>
    );
}

export default Card;