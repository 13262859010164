import React from 'react';
import { connect } from 'react-redux';
import Spinner from '../components/Spinner';
import Header from '../components/Header';
import InlineReservationForm from '../components/InlineReservationForm';

import { fetchRoomTypes } from '../actions/rooms';

function Sleeps(props) {
    return (
        <div className="room-type__sleeps bg-brand p-4" style={{marginTop: 0}}>
            <svg className="room-type__sleeps__icon room-type__icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M544 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM320 256c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm0-192c44.1 0 80 35.9 80 80s-35.9 80-80 80-80-35.9-80-80 35.9-80 80-80zm244 192h-40c-15.2 0-29.3 4.8-41.1 12.9 9.4 6.4 17.9 13.9 25.4 22.4 4.9-2.1 10.2-3.3 15.7-3.3h40c24.2 0 44 21.5 44 48 0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.1-34.1-80-76-80zM96 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm304.1 180c-33.4 0-41.7 12-80.1 12-38.4 0-46.7-12-80.1-12-36.3 0-71.6 16.2-92.3 46.9-12.4 18.4-19.6 40.5-19.6 64.3V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-44.8c0-23.8-7.2-45.9-19.6-64.3-20.7-30.7-56-46.9-92.3-46.9zM480 432c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16v-44.8c0-16.6 4.9-32.7 14.1-46.4 13.8-20.5 38.4-32.8 65.7-32.8 27.4 0 37.2 12 80.2 12s52.8-12 80.1-12c27.3 0 51.9 12.3 65.7 32.8 9.2 13.7 14.1 29.8 14.1 46.4V432zM157.1 268.9c-11.9-8.1-26-12.9-41.1-12.9H76c-41.9 0-76 35.9-76 80 0 8.8 7.2 16 16 16s16-7.2 16-16c0-26.5 19.8-48 44-48h40c5.5 0 10.8 1.2 15.7 3.3 7.5-8.5 16.1-16 25.4-22.4z"></path></svg>
            <span className="room-type__sleeps__label">Max</span>
            <span className="room-type__sleeps__number">{ props.sleeps }</span>
        </div>
    );
}

function Features(props) {
    return (
        <div className="features-list">
            <h4>Features</h4>
            { props.features.map((feature) => {
                return (
                    <div className="feature" key={ feature }>
                        <FeatureIcon feature={ feature } />
                        <span className="feature__label">{ feature }</span>
                    </div>
                )
            })}
        </div>
    );
}

function FeatureIcon(props) {
    switch (props.feature) {
        case 'tv': return (
            <svg className="feature__icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M416 243v-8c0-6.6 5.4-12 12-12h8c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12h-8c-6.6 0-12-5.4-12-12zm96-100v288c0 26.5-21.5 48-48 48h-16v33h-16l-11-33H91l-11 33H64v-33H48c-26.5 0-48-21.5-48-48V143c0-26.5 21.5-48 48-48h160.1l-74.8-67.1c-6.6-5.9-7.2-16-1.3-22.6 5.9-6.6 16-7.2 22.6-1.3L256 95h.8L357.3 4.1c6.6-5.9 16.7-5.4 22.6 1.2 5.9 6.6 5.4 16.7-1.2 22.6L304.5 95H464c26.5 0 48 21.5 48 48zm-32 0c0-8.8-7.2-16-16-16H48c-8.8 0-16 7.2-16 16v288c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16V143zm-256 49c-68.8 0-106.2 2.3-125.3 4.3-1.3 14.4-2.7 41.5-2.7 90.7 0 50.5 1.4 78 2.7 92.8 19.2 1.9 56.5 4.2 125.3 4.2s106.1-2.3 125.3-4.2c1.3-14.7 2.7-42.3 2.7-92.8 0-49.2-1.4-76.3-2.7-90.7-19.1-2-56.5-4.3-125.3-4.3m0-32c128 0 152 8 152 8s8 0 8 119c0 121-8 121-8 121s-24 8-152 8-152-8-152-8-8 0-8-121c0-119 8-119 8-119s24-8 152-8zm204 159h8c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12z"></path></svg>
        );
        case 'wifi': return (
            <svg className="feature__icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M320 320c-44.18 0-80 35.82-80 80 0 44.19 35.83 80 80 80 44.19 0 80-35.84 80-80 0-44.18-35.82-80-80-80zm0 128c-26.47 0-48-21.53-48-48s21.53-48 48-48 48 21.53 48 48-21.53 48-48 48zm316.21-290.05C459.22-9.9 180.95-10.06 3.79 157.95c-4.94 4.69-5.08 12.51-.26 17.32l5.69 5.69c4.61 4.61 12.07 4.74 16.8.25 164.99-156.39 423.64-155.76 587.97 0 4.73 4.48 12.19 4.35 16.8-.25l5.69-5.69c4.81-4.81 4.67-12.63-.27-17.32zM526.02 270.31c-117.34-104.48-294.86-104.34-412.04 0-5.05 4.5-5.32 12.31-.65 17.2l5.53 5.79c4.46 4.67 11.82 4.96 16.66.67 105.17-93.38 264-93.21 368.98 0 4.83 4.29 12.19 4.01 16.66-.67l5.53-5.79c4.65-4.89 4.38-12.7-.67-17.2z"></path></svg>
        )
        case 'parking': return (
            <svg className="feature__icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M268.1 127.91H176c-4.42 0-8 3.58-8 8v240c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-72h72c51.43 0 92.68-44.32 87.57-96.78-4.45-45.71-45.54-79.22-91.47-79.22zm3.9 144h-72v-112h72c30.88 0 56 25.12 56 56s-25.12 56-56 56zM248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm0 464c-119.1 0-216-96.9-216-216S128.9 40 248 40s216 96.9 216 216-96.9 216-216 216z"></path></svg>
        )
        default: return null;
    }
}

class Room extends React.Component {
    componentDidMount() {
        const { fetchRooms } = this.props;

        fetchRooms();
    }

    render() {
        const { room, loading } = this.props;

        if (loading) {
            return (
                <>
                    <Header backTo="/rooms/" />
                    <Spinner />
                </>
            );
        }

        if ( ! room) {
            return null;
        }

        return (
            <>
                <Header backTo="/rooms/" />
                <h1 className="page-title">{ room.name.en }</h1>
                { room.image && <div className="event-image"><img src={ room.image } alt={ room.name.en } className="block" /></div> }
                <Sleeps sleeps={ room.sleeps } />
                { room.content.length > 0 && <div className="page-content mt-4 mb-8" dangerouslySetInnerHTML={{ __html: room.content.en }}></div> }
                { room.description.length > 0 && <div className="page-content mt-4 mb-8" dangerouslySetInnerHTML={{ __html: room.description.en }}></div> }
                <Features features={ room.features } />
                <InlineReservationForm roomId={ room.id } />
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({
    room: state.rooms.types.find(room => room.id === parseInt(props.match.params.id, 10)),
    loading: state.rooms.loading,
    error: state.rooms.error
});

const mapDispatchToProps = (dispatch) => ({
    fetchRooms: () => {
        dispatch(fetchRoomTypes())
    }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Room);