import { RECEIVE_STATIC_PAGE, STATIC_PAGE_FETCHING_FAILED, START_FETCHING_STATIC_PAGE } from '../actions/staticPage';

export const staticPage = (state = {
    data: [],
    loading: false,
    error: false
}, action) => {
    switch (action.type) {
        case RECEIVE_STATIC_PAGE:
            return Object.assign({}, state, {
                data: action.data,
                loading: false,
                error: false
            });
        case STATIC_PAGE_FETCHING_FAILED:
            return Object.assign({}, state, {
                data: [],
                error: true,
                loading: false
            });
        case START_FETCHING_STATIC_PAGE:
            return Object.assign({}, state, {
                loading: true
            });
        default:
            return Object.assign({}, state);
    }
}