import { RECEIVE_ROOM_TYPES, ROOM_TYPES_FETCHING_FAILED, START_FETCHING_ROOM_TYPES } from '../actions/rooms';

export const rooms = (state = {
    types: [],
    loading: false,
    error: false
}, action) => {
    switch (action.type) {
        case RECEIVE_ROOM_TYPES:
            return Object.assign({}, state, {
                types: action.types,
                loading: false,
                error: false
            });
        case ROOM_TYPES_FETCHING_FAILED:
            return Object.assign({}, state, {
                types: [],
                error: true,
                loading: false
            });
        case START_FETCHING_ROOM_TYPES:
            return Object.assign({}, state, {
                loading: true
            });
        default:
            return Object.assign({}, state);
    }
}