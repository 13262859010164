import { login } from '../helpers/api';

export const START_LOGIN = 'START_LOGIN';
function startLogin() {
    return {
        type: START_LOGIN,
        loading: true,
        errors: [],
    }
}

export const LOGIN_FAILED = 'LOGIN_FAILED';
function loginFailed(errors) {
    return {
        type: LOGIN_FAILED,
        loading: false,
        errors: errors,
    }
}

export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
function loginSucceeded() {
    return {
        type: LOGIN_SUCCEEDED,
        loading: false,
        errors: [],
    }
}

export function guestLogin(data) {
    return (dispatch) => {
        dispatch(startLogin());

        return login(data).then((response) => {
            return dispatch(loginSucceeded());
        }).catch((error) => {
            if (error.response && error.response.data.errors) {
                return dispatch(loginFailed(error.response.data.errors));
            }

            return dispatch(loginFailed(["Unexpected error occured"]));
        });
    }
}