import { makeReservation } from '../helpers/api';

export const START_ROOM_RESERVATION = 'START_ROOM_RESERVATION';
function startRoomReservation() {
    return {
        type: START_ROOM_RESERVATION,
        loading: true,
        success: false,
        errors: [],
        message: '',
    }
}

export const ROOM_RESERVATION_FAILED = 'ROOM_RESERVATION_FAILED';
function roomReservationFailed(errors) {
    return {
        type: ROOM_RESERVATION_FAILED,
        loading: false,
        success: false,
        errors: errors,
        message: '',
    }
}

export const ROOM_RESERVATION_SUCCEEDED = 'ROOM_RESERVATION_SUCCEEDED';
function roomReservationSucceeded(message) {
    return {
        type: ROOM_RESERVATION_SUCCEEDED,
        loading: false,
        success: true,
        errors: [],
        message: message,
    }
}

export function reserveARoom(data) {
    return (dispatch) => {
        dispatch(startRoomReservation());

        return makeReservation(data).then((response) => {
            if (response.status !== 200) {
                return dispatch(roomReservationFailed(response));
            }

            return dispatch(roomReservationSucceeded(response.data.message.en));
        }).catch((error) => {
            if (error.response && error.response.data.errors) {
                return dispatch(roomReservationFailed(error.response.data.errors));
            }

            return dispatch(roomReservationFailed(["Unexpected error occured"]));
        });
    }
}