import { getStaticPage } from '../helpers/api';

export const START_FETCHING_STATIC_PAGE = 'START_FETCHING_STATIC_PAGE';
function startFetchingStaticPage() {
    return {
        type: START_FETCHING_STATIC_PAGE,
        loading: true
    }
}

export const STATIC_PAGE_FETCHING_FAILED = 'STATIC_PAGE_FETCHING_FAILED';
function staticPageFetchingFailed() {
    return {
        type: STATIC_PAGE_FETCHING_FAILED
    }
}

export const RECEIVE_STATIC_PAGE = 'RECEIVE_STATIC_PAGE';
function receiveStaticPage(data) {
    return {
        type: RECEIVE_STATIC_PAGE,
        data
    }
}

export function fetchStaticPage(slug) {
    return (dispatch, getState) => {
        dispatch(startFetchingStaticPage());

        const { staticPage } = getState();

        if (staticPage.data.length > 0) {
            return dispatch(receiveStaticPage(staticPage.data));
        }

        return getStaticPage(slug).then((response) => {
            if (response.status !== 200) {
                return dispatch(staticPageFetchingFailed());
            }

            return dispatch(receiveStaticPage(response.data.data));
        });
    }
}