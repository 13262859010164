import { roomTypes } from '../helpers/api';

export const START_FETCHING_ROOM_TYPES = 'START_FETCHING_ROOM_TYPES';
function startFetchingRoomTypes() {
    return {
        type: START_FETCHING_ROOM_TYPES,
        loading: true
    }
}

export const ROOM_TYPES_FETCHING_FAILED = 'ROOM_TYPES_FETCHING_FAILED';
function roomTypesFetchingFailed() {
    return {
        type: ROOM_TYPES_FETCHING_FAILED
    }
}

export const RECEIVE_ROOM_TYPES = 'RECEIVE_ROOM_TYPES';
function receiveRoomTypes(types) {
    return {
        type: RECEIVE_ROOM_TYPES,
        types
    }
}

export function fetchRoomTypes() {
    return (dispatch, getState) => {
        dispatch(startFetchingRoomTypes());

        const { rooms } = getState();

        if (rooms.types.length > 0) {
            return dispatch(receiveRoomTypes(rooms.types));
        }

        return roomTypes().then((response) => {
            if (response.status !== 200) {
                return dispatch(roomTypesFetchingFailed());
            }

            return dispatch(receiveRoomTypes(response.data.data));
        });
    }
}