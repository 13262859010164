import React from 'react';
import { connect } from 'react-redux';
import Spinner from '../components/Spinner';
import Header from '../components/Header';
import Address from '../components/Address';
import Location from '../components/Location';

import { fetchHotelInfo } from '../actions/info';

function Contact(props) {
    return (
        <div className="event-address bg-brand-light p-4">
            <h3 className="event-address-label text-brand-dark text-xs font-normal mb-2">
                <svg className="mr-2" style={{ width: '10px', height: '10px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path></svg>
                Contact
            </h3>
            <div className="event-address-text text-white" dangerouslySetInnerHTML={{ __html: props.contact.en }}></div>
        </div>
    );
}

class HotelInfo extends React.Component {
    componentDidMount() {
        const { fetchInfo } = this.props;

        fetchInfo();
    }

    render() {
        const { data, loading } = this.props;

        if (loading) {
            return (
                <>
                    <Header backTo="//" />
                    <Spinner />
                </>
            );
        }

        if (data.length === 0) {
            return null;
        }

        return (
            <>
                <Header backTo="/" />
                <h1 className="page-title">{ data.name.en }</h1>
                <div className="page-content mt-4 mb-8" dangerouslySetInnerHTML={{ __html: data.about.en }}></div>
                <Contact { ...data } />
                <Address { ...data } />
                <Location { ...data } />
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({
    data: state.info.data,
    loading: state.info.loading,
    error: state.info.error
});

const mapDispatchToProps = (dispatch) => ({
    fetchInfo: () => {
        dispatch(fetchHotelInfo())
    }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HotelInfo);