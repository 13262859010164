import React from 'react';
import { connect } from 'react-redux';
import CurrentWeather from '../components/CurrentWeather';
import WeatherIcon from '../components/WeatherIcon';
import Spinner from '../components/Spinner';
import Header from '../components/Header';

import format from 'date-fns/format';

import { fetchWeatherForecast } from '../actions/weather';


class Weather extends React.Component {
    componentDidMount() {
        const { fetchForecast } = this.props;

        fetchForecast();
    }

    render() {
        const { forecast, loading } = this.props;

        if (loading) {
            return (
                <>
                    <Header backTo="/" />
                    <Spinner />
                </>
            );
        }

        if (forecast.length === 0) {
            return null;
        }

        return (
            <>
                <Header backTo="/" />
                <CurrentWeather />
                <div className="forecast">
                    { forecast.map((day) => {
                        return (
                            <div className="forecast__item" key={ day.date }>
                                <div className="forecast__item__date">{ format(new Date(day.date), 'ddd, MMM DD.') }</div>
                                <div className="forecast__item__icon"><WeatherIcon icon={ day.icon } /></div>
                                <div className="forecast__item__data">
                                    <div className="forecast__item__temp">{ day.temp }&deg;</div>
                                    <div className="forecast__item__minmax">
                                        Min: { day.min }&deg;<br />
                                        Max: { day.max }&deg;
                                    </div>
                                </div>
                            </div>
                        );
                    }) }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    forecast: state.weather.forecast,
    loading: state.weather.loading,
    error: state.weather.error
});

const mapDispatchToProps = (dispatch) => ({
    fetchForecast: () => {
        dispatch(fetchWeatherForecast())
    }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Weather);