import { getHotelInfo } from '../helpers/api';

export const START_FETCHING_HOTEL_INFO = 'START_FETCHING_HOTEL_INFO';
function startFetchingHotelInfo() {
    return {
        type: START_FETCHING_HOTEL_INFO,
        loading: true
    }
}

export const HOTEL_INFO_FETCHING_FAILED = 'HOTEL_INFO_FETCHING_FAILED';
function hotelInfoFetchingFailed() {
    return {
        type: HOTEL_INFO_FETCHING_FAILED
    }
}

export const RECEIVE_HOTEL_INFO = 'RECEIVE_HOTEL_INFO';
function receiveHotelInfo(data) {
    return {
        type: RECEIVE_HOTEL_INFO,
        data
    }
}

export function fetchHotelInfo() {
    return (dispatch, getState) => {
        dispatch(startFetchingHotelInfo());

        const { info } = getState();

        if (info.data.length > 0) {
            return dispatch(receiveHotelInfo(info.data));
        }

        return getHotelInfo().then((response) => {
            if (response.status !== 200) {
                return dispatch(hotelInfoFetchingFailed());
            }

            return dispatch(receiveHotelInfo(response.data.data));
        });
    }
}