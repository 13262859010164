import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

function MapContainer(props) {
    return (
        <Map
            google={ props.google }
            zoom={ props.zoom }
            initialCenter={ props.position }
            style={{ width: '100%', height: '300px', position: 'relative' }}
            containerStyle={{ width: '100%', height: '300px', position: 'relative' }}
        >
            <Marker name={ props.label } position={ props.position }></Marker>
        </Map>
    );
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBevTjsHmDsgIdvDQYEpfrK8kfiMxQSf-o'
})(MapContainer);

