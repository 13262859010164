import { currentWeather, weatherForecast } from '../helpers/api';

export const START_FETCHING_WEATHER = 'START_FETCHING_WEATHER';
function startFetchingWeather() {
    return {
        type: START_FETCHING_WEATHER,
        loading: true
    }
}

export const WEATHER_FETCHING_FAILED = 'WEATHER_FETCHING_FAILED';
function weatherFetchingFailed() {
    return {
        type: WEATHER_FETCHING_FAILED
    }
}

export const RECEIVE_CURRENT_WEATHER = 'RECEIVE_CURRENT_WEATHER';
function receiveCurrentWeather(current) {
    return {
        type: RECEIVE_CURRENT_WEATHER,
        current
    }
}

export const RECEIVE_WEATHER_FORECAST = 'RECEIVE_WEATHER_FORECAST';
function receiveWeatherForecast(forecast) {
    return {
        type: RECEIVE_WEATHER_FORECAST,
        forecast
    }
}

export function fetchCurrentWeather() {
    return (dispatch, getState) => {
        dispatch(startFetchingWeather());

        const { weather } = getState();

        if (weather.current) {
            return dispatch(receiveCurrentWeather(weather.current));
        }

        return currentWeather().then((response) => {
            if (response.status !== 200) {
                return dispatch(weatherFetchingFailed());
            }

            return dispatch(receiveCurrentWeather(response.data.data));
        }).catch(() => {
            return dispatch(weatherFetchingFailed());
        });
    }
}

export function fetchWeatherForecast() {
    return (dispatch, getState) => {
        dispatch(startFetchingWeather());

        const { weather } = getState();

        if (weather.forecast.length > 0) {
            return dispatch(receiveWeatherForecast(weather.forecast));
        }

        return weatherForecast().then((response) => {
            if (response.status !== 200) {
                return dispatch(weatherFetchingFailed());
            }

            return dispatch(receiveWeatherForecast(response.data.data));
        });
    }
}