import { RECEIVE_FUTURE_EVENTS, EVENTS_FETCHING_FAILED, START_FETCHING_EVENTS } from '../actions/events';

export const events = (state = {
    future: [],
    loading: false,
    error: false
}, action) => {
    switch (action.type) {
        case RECEIVE_FUTURE_EVENTS:
            return Object.assign({}, state, {
                future: action.future,
                loading: false,
                error: false
            });
        case EVENTS_FETCHING_FAILED:
            return Object.assign({}, state, {
                future: [],
                error: true,
                loading: false
            });
        case START_FETCHING_EVENTS:
            return Object.assign({}, state, {
                loading: true
            });
        default:
            return Object.assign({}, state);
    }
}