import React from 'react';
import { connect } from 'react-redux';

import Spinner from './Spinner';
import { guestLogin } from '../actions/login';

function Button(props) {
    const { loading } = props;

    return (
        <button className="button button--activate" type="submit" disabled={ loading }>
            { loading ? <Spinner type="small" /> : "Activate" }
        </button>
    );
}

function Errors(props) {
    const { errors } = props;

    if ( ! errors || errors.length === 0) {
        return null;
    }

    const messages = Object.keys(errors).map((key, index) => {
        return errors[key];
    });

    return (
        <div className="reservation-errors">
            <svg className="reservation__icon" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z"></path></svg>
            <ul className="reservation-errors__messages">
            {
                 messages.map((error, key) => {
                    return (
                        <li key={ key }>{ error }</li>
                    );
                })
            }
            </ul>
        </div>
    );
}

class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            roomNumber: '',
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { login } = this.props;

        login({
            'email': this.state.email,
            'room_number': this.state.roomNumber,
        });
    }

    render() {
        const { loading, errors } = this.props;
        return (
            <>
                <form onSubmit={ this.handleSubmit } className="reservation-form">
                    <div className="form-row form-row--full">
                        <div className="form-field">
                            <label htmlFor="email">Email</label>
                            <input id="email" onChange={ this.handleInputChange } name="email" type="email" value={ this.state.email } required />
                        </div>
                        <div className="form-field">
                            <label htmlFor="roomNumber">Room Number</label>
                            <input id="roomNumber" onChange={ this.handleInputChange } name="roomNumber" type="text" value={ this.state.roomNumber } required />
                        </div>
                    </div>
                    <Errors errors={ errors } />
                    <div className="button-container">
                        <Button loading={ loading } />
                    </div>
                </form>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.login.loading,
    errors: state.login.errors,
});

const mapDispatchToProps = (dispatch) => ({
    login: (data) => {
        dispatch(guestLogin(data));
    }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);