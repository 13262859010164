import React from 'react';

import Card from '../components/Home/Card';
import CenteredLogo from '../components/CenteredLogo';
import CurrentWeather from '../components/CurrentWeather';
import ActivateButton from '../components/ActivateButton';

import RoomsCardImage from '../images/main/rooms.png';
import RestaurantCardImage from '../images/main/restaurant.png';
import ConferenceCardImage from '../images/main/conference.png';
import ConciergeCardImage from '../images/main/concierge.png';
import WalletCardImage from '../images/main/wallet.png';
import GuideCardImage from '../images/main/guide.png';
import WifiCardImage from '../images/main/wifi.png';
import AboutCardImage from '../images/main/about.png';
import GalleryCardImage from '../images/main/gallery.png';

function Home() {
    return (
        <div>
            <CenteredLogo />
            <div className="flex justify-end p-2">
                <ActivateButton className="mr-2"></ActivateButton>
                <a href="http://www.hotelcentralsb.hr/en/reservation/" className="reserve-button reserve-button--homepage" target="_blank" rel="noopener noreferrer">Book a room</a>
            </div>
            <nav className="mt-4">
                <Card label="Rooms" path="/rooms/" image={ RoomsCardImage } icon={ <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="card__icon"><path fill="currentColor" d="M396.8 224h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm-128-96h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm128 0h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm-256 0h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm128 96h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zM568 32c4.42 0 8-3.58 8-8V8c0-4.42-3.58-8-8-8H8C3.58 0 0 3.58 0 8v16c0 4.42 3.58 8 8 8h23.98v448H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h560c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8h-24V32h24zM320 480h-64v-80c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v80zm192 0H352v-80c0-26.47-21.53-48-48-48h-32c-26.47 0-48 21.53-48 48v80H63.98V32H512v448zM140.8 224h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm26.31 157.66l16.25 2.26c4.3.6 8.11-2.24 9.07-6.36 9.96-42.83 49.74-74.28 95.58-74.28s85.61 31.45 95.58 74.28c.96 4.12 4.77 6.96 9.07 6.36l16.25-2.26c4.6-.64 7.9-4.92 6.94-9.34C403.22 314.29 349.72 271.5 288 271.5s-115.22 42.79-127.83 100.81c-.96 4.43 2.34 8.71 6.94 9.35z"></path></svg> }></Card>
                <Card label="Bar &amp; Restaurant" path="/restaurant/" image={ RestaurantCardImage } icon={ <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 512" className="card__icon"><path fill="currentColor" d="M344.1 470.3l14.2-164.8c-42.1-33.1-70.4-77-70.4-129.5C288 81.7 376.1 0 440 0c22.1 0 40 17.3 40 38.5v435c0 21.2-17.9 38.5-40 38.5h-56c-22.8 0-41.8-18.7-39.9-41.7zM320 176c0 51 32.2 85.5 71.8 114.5L376 473.1c-.3 3.7 3.4 6.9 8 6.9h56c4.3 0 8-3 8-6.5v-435c0-3.5-3.7-6.5-8-6.5-44.6 0-120 65.8-120 144zM240.7 33.8C237.4 14.3 219.5 0 194.6 0c-11.9 0-24.1 3.4-33.3 11.2C152.9 4.1 141.3 0 128 0s-24.9 4.1-33.3 11.2C85.5 3.4 73.3 0 61.4 0 36.2 0 18.6 14.5 15.3 33.8 13.5 43.2 0 118.4 0 149.9c0 50.9 26.7 91.6 71 110.7L59.6 471.4C58.4 493.4 75.9 512 98 512h60c22 0 39.6-18.5 38.4-40.6L185 260.6c44.2-19.1 71-59.8 71-110.7 0-31.5-13.5-106.7-15.3-116.1zM152.3 240l12.2 233.1c.2 3.7-2.7 6.9-6.5 6.9H98c-3.7 0-6.7-3.1-6.5-6.9L103.7 240C61.3 231.2 32 197 32 149.9c0-29.7 14.8-110.6 14.8-110.6 1.6-9.9 28.3-9.7 29.5.2V162c.9 11.5 28.2 11.7 29.5.2l7.4-122.9c1.6-9.7 27.9-9.7 29.5 0l7.4 122.9c1.3 11.4 28.6 11.2 29.5-.2V39.6c1.2-9.9 27.9-10.1 29.5-.2 0 0 14.8 80.9 14.8 110.6.1 46.8-29 81.2-71.6 90z"></path></svg> }></Card>
                <Card label="Conference" path="/conference/" image={ ConferenceCardImage } icon={ <svg className="card__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M568 0H8C3.58 0 0 3.59 0 8.02v16.04c0 4.43 3.58 8.02 8 8.02h24v287.78c0 17.69 14.34 32.09 32 32.09h208v49.13l-85.66 85.89c-3.12 3.13-3.12 8.21 0 11.34l11.31 11.34a7.985 7.985 0 0 0 11.31 0L288 430.41l79.03 79.24a7.985 7.985 0 0 0 11.31 0l11.31-11.34c3.12-3.13 3.12-8.21 0-11.34L304 401.08v-49.13h208c17.66 0 32-14.4 32-32.09V32.09h24c4.42 0 8-3.59 8-8.02V8.02c0-4.43-3.58-8.02-8-8.02zm-56 320H64V32h448v288z"></path></svg> }></Card>
                <div className="flex">
                    <div className="w-1/2" style={{ "marginRight": "0.125rem" }}>
                        <Card label="Digital Concierge" path="/concierge/" image={ ConciergeCardImage } icon={ <svg className="card__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M504 416h-24v-64c0-118.31-91.79-214.96-208-223.19V96h40c4.42 0 8-3.58 8-8V72c0-4.42-3.58-8-8-8H200c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h40v32.81C123.79 137.04 32 233.69 32 352v64H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h496c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zm-56 0H64v-64c0-100.17 78.3-184.19 178.26-191.27 16.87-1.19 10.33-1.21 27.48 0C369.7 167.81 448 251.83 448 352v64z"></path></svg> }></Card>
                    </div>
                    <div className="w-1/2" style={{ "marginLeft": "0.125rem" }}>
                        <Card label="Wallet" path="/wallet/" image={ WalletCardImage } icon={ <svg className="card__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M384 256c-17.67 0-32 14.33-32 32s14.33 32 32 32 32-14.33 32-32-14.33-32-32-32zm80-160h-16V80c0-26.51-21.49-48-48-48H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96 96 96h352c35.35 0 64-28.65 64-64V144c0-26.51-21.49-48-48-48zm16 320c0 17.64-14.36 32-32 32H96c-35.29 0-64-28.71-64-64V128c0-35.29 28.71-64 64-64h304c8.82 0 16 7.18 16 16v16H112c-8.84 0-16 7.16-16 16s7.16 16 16 16h352c8.82 0 16 7.18 16 16v272z"></path></svg> }></Card>
                    </div>
                </div>
                <Card label="Tourist Guide" path="/guide/" image={ GuideCardImage } icon={ <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="card__icon"><path fill="currentColor" d="M312 128c0-13.26-10.75-24-24-24s-24 10.74-24 24c0 13.25 10.75 24 24 24s24-10.74 24-24zm248.02 32c-1.96 0-3.98.37-5.96 1.16L384.01 224H384l-10.27-3.62c23.3-34.86 42.28-71.2 42.28-97.33C416 55.09 358.69 0 288 0S160 55.09 160 123.05c0 11.8 4.02 25.75 10.39 40.64L20.12 215.96C7.97 220.81 0 232.58 0 245.67v250.32C0 505.17 7.53 512 15.99 512c1.96 0 3.97-.37 5.96-1.16L192 448l172 60.71a63.98 63.98 0 0 0 40.05.15l151.83-52.81A31.996 31.996 0 0 0 576 426.34V176.02c0-9.19-7.53-16.02-15.98-16.02zM176 419.8L31.91 473.05l-1.28-226.87L176 195.61V419.8zM288 32c52.94 0 96 40.84 96 91.05 0 27-38.09 88.89-96 156.77-57.9-67.88-96-129.77-96-156.77C192 72.84 235.06 32 288 32zm80 444.19l-160-56.48V228.82c24.42 35.27 52.14 68 67.71 85.66 3.24 3.68 7.77 5.52 12.29 5.52s9.05-1.84 12.29-5.52c12.76-14.47 33.7-39.11 54.28-66.94l13.42 4.74v223.91zm32 .2V252.21l144.09-53.26 1.28 226.87L400 476.39z"></path></svg> }></Card>
                <div className="flex">
                    <div className="w-1/2" style={{ "marginRight": "0.125rem" }}>
                        <Card label="Enjoy free wi-fi internet with us" image={ WifiCardImage } icon={ <svg className="card__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M320 320c-44.18 0-80 35.82-80 80 0 44.19 35.83 80 80 80 44.19 0 80-35.84 80-80 0-44.18-35.82-80-80-80zm0 128c-26.47 0-48-21.53-48-48s21.53-48 48-48 48 21.53 48 48-21.53 48-48 48zm316.21-290.05C459.22-9.9 180.95-10.06 3.79 157.95c-4.94 4.69-5.08 12.51-.26 17.32l5.69 5.69c4.61 4.61 12.07 4.74 16.8.25 164.99-156.39 423.64-155.76 587.97 0 4.73 4.48 12.19 4.35 16.8-.25l5.69-5.69c4.81-4.81 4.67-12.63-.27-17.32zM526.02 270.31c-117.34-104.48-294.86-104.34-412.04 0-5.05 4.5-5.32 12.31-.65 17.2l5.53 5.79c4.46 4.67 11.82 4.96 16.66.67 105.17-93.38 264-93.21 368.98 0 4.83 4.29 12.19 4.01 16.66-.67l5.53-5.79c4.65-4.89 4.38-12.7-.67-17.2z"></path></svg> }></Card>
                    </div>
                    <div className="w-1/2" style={{ "marginLeft": "0.125rem" }}>
                        <Card label="About" path="/info/" image={ AboutCardImage } icon={ <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="card__icon"><path fill="currentColor" d="M396.8 224h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm-128-96h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm128 0h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm-256 0h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm128 96h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zM568 32c4.42 0 8-3.58 8-8V8c0-4.42-3.58-8-8-8H8C3.58 0 0 3.58 0 8v16c0 4.42 3.58 8 8 8h23.98v448H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h560c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8h-24V32h24zM320 480h-64v-80c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v80zm192 0H352v-80c0-26.47-21.53-48-48-48h-32c-26.47 0-48 21.53-48 48v80H63.98V32H512v448zM140.8 224h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm26.31 157.66l16.25 2.26c4.3.6 8.11-2.24 9.07-6.36 9.96-42.83 49.74-74.28 95.58-74.28s85.61 31.45 95.58 74.28c.96 4.12 4.77 6.96 9.07 6.36l16.25-2.26c4.6-.64 7.9-4.92 6.94-9.34C403.22 314.29 349.72 271.5 288 271.5s-115.22 42.79-127.83 100.81c-.96 4.43 2.34 8.71 6.94 9.35z"></path></svg> }></Card>
                    </div>
                </div>
                <Card label="Central Gallery" path="/gallery/" image={ GalleryCardImage } icon={ <svg xmlns="http://www.w3.org/2000/svg" className="card__icon" viewBox="0 0 576 512"><path fill="currentColor" d="M528 32H112c-26.51 0-48 21.49-48 48v16H48c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48v-16h16c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm-48 400c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V144c0-8.822 7.178-16 16-16h16v240c0 26.51 21.49 48 48 48h368v16zm64-64c0 8.822-7.178 16-16 16H112c-8.822 0-16-7.178-16-16V80c0-8.822 7.178-16 16-16h416c8.822 0 16 7.178 16 16v288zM176 200c30.928 0 56-25.072 56-56s-25.072-56-56-56-56 25.072-56 56 25.072 56 56 56zm0-80c13.234 0 24 10.766 24 24s-10.766 24-24 24-24-10.766-24-24 10.766-24 24-24zm240.971 23.029c-9.373-9.373-24.568-9.373-33.941 0L288 238.059l-31.029-31.03c-9.373-9.373-24.569-9.373-33.941 0l-88 88A24.002 24.002 0 0 0 128 312v28c0 6.627 5.373 12 12 12h360c6.627 0 12-5.373 12-12v-92c0-6.365-2.529-12.47-7.029-16.971l-88-88zM480 320H160v-4.686l80-80 48 48 112-112 80 80V320z"></path></svg> }></Card>
            </nav>
            <CurrentWeather />
        </div>
    );
}

export default Home;