import { START_ROOM_RESERVATION, ROOM_RESERVATION_FAILED, ROOM_RESERVATION_SUCCEEDED } from '../actions/reservations';

export const reservations = (state = {
    loading: false,
    success: false,
    errors: [],
    message: '',
}, action) => {
    switch (action.type) {
        case START_ROOM_RESERVATION:
            return Object.assign({}, state, {
                success: false,
                loading: true,
                errors: [],
                message: '',
            });
        case ROOM_RESERVATION_FAILED:
            return Object.assign({}, state, {
                loading: false,
                success: false,
                errors: action.errors,
                message: '',
            });
        case ROOM_RESERVATION_SUCCEEDED:
            return Object.assign({}, state, {
                loading: false,
                success: true,
                errors: [],
                message: action.message,
            });
        default:
            return Object.assign({}, state);
    }
}