import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Spinner from '../components/Spinner';
import Header from '../components/Header';

import { fetchRoomTypes } from '../actions/rooms';

function RoomType(props) {
    const { type } = props;
    return (
        <Link className="room-type" to={ '/rooms/' + type.id }>
            { type.image && <img src={ type.image } alt={ type.name.en } className="room-type__image" /> }
            <div className={ type.image ? 'room-type__info room-type__info--overlay' : 'room-type__info' }>
                <h2 className="room-type__name">{ type.name.en }</h2>
                <div className="room-type__sleeps">
                    <svg className="room-type__sleeps__icon room-type__icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M544 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM320 256c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm0-192c44.1 0 80 35.9 80 80s-35.9 80-80 80-80-35.9-80-80 35.9-80 80-80zm244 192h-40c-15.2 0-29.3 4.8-41.1 12.9 9.4 6.4 17.9 13.9 25.4 22.4 4.9-2.1 10.2-3.3 15.7-3.3h40c24.2 0 44 21.5 44 48 0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.1-34.1-80-76-80zM96 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm304.1 180c-33.4 0-41.7 12-80.1 12-38.4 0-46.7-12-80.1-12-36.3 0-71.6 16.2-92.3 46.9-12.4 18.4-19.6 40.5-19.6 64.3V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-44.8c0-23.8-7.2-45.9-19.6-64.3-20.7-30.7-56-46.9-92.3-46.9zM480 432c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16v-44.8c0-16.6 4.9-32.7 14.1-46.4 13.8-20.5 38.4-32.8 65.7-32.8 27.4 0 37.2 12 80.2 12s52.8-12 80.1-12c27.3 0 51.9 12.3 65.7 32.8 9.2 13.7 14.1 29.8 14.1 46.4V432zM157.1 268.9c-11.9-8.1-26-12.9-41.1-12.9H76c-41.9 0-76 35.9-76 80 0 8.8 7.2 16 16 16s16-7.2 16-16c0-26.5 19.8-48 44-48h40c5.5 0 10.8 1.2 15.7 3.3 7.5-8.5 16.1-16 25.4-22.4z"></path></svg>
                    <span className="room-type__sleeps__label">Max</span>
                    <span className="room-type__sleeps__number">{ type.sleeps }</span>
                </div>
            </div>
        </Link>
    );
}

class Rooms extends React.Component {
    componentDidMount() {
        const { fetchTypes } = this.props;

        fetchTypes();
    }

    render() {
        const { types, loading } = this.props;

        if (loading) {
            return (
                <>
                    <Header backTo="/" />
                    <h1 className="page-title">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="page-title__icon"><path fill="currentColor" d="M144 320c44.11 0 80-35.89 80-80s-35.89-80-80-80-80 35.89-80 80 35.89 80 80 80zm0-128c26.47 0 48 21.53 48 48s-21.53 48-48 48-48-21.53-48-48 21.53-48 48-48zm384-32H272c-8.84 0-16 7.16-16 16v176H32V72c0-4.42-3.58-8-8-8H8c-4.42 0-8 3.58-8 8v368c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-56h576v56c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8V272c0-61.86-50.14-112-112-112zm80 192H288V192h240c44.11 0 80 35.89 80 80v80z"></path></svg>
                        <span className="page-title__label">Rooms</span>
                    </h1>
                    <Spinner />
                </>
            );
        }

        if (types.length === 0) {
            return null;
        }

        return (
            <>
                <Header backTo="/" />
                <h1 className="page-title">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="page-title__icon"><path fill="currentColor" d="M144 320c44.11 0 80-35.89 80-80s-35.89-80-80-80-80 35.89-80 80 35.89 80 80 80zm0-128c26.47 0 48 21.53 48 48s-21.53 48-48 48-48-21.53-48-48 21.53-48 48-48zm384-32H272c-8.84 0-16 7.16-16 16v176H32V72c0-4.42-3.58-8-8-8H8c-4.42 0-8 3.58-8 8v368c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-56h576v56c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8V272c0-61.86-50.14-112-112-112zm80 192H288V192h240c44.11 0 80 35.89 80 80v80z"></path></svg>
                    <span className="page-title__label">Rooms</span>
                </h1>
                <div className="room-types">
                    { types.map(type => {
                        return (
                            <RoomType type={ type } key={ type.id } />
                        );
                    }) }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    types: state.rooms.types,
    loading: state.rooms.loading,
    error: state.rooms.error
});

const mapDispatchToProps = (dispatch) => ({
    fetchTypes: () => {
        dispatch(fetchRoomTypes())
    }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Rooms);