import React from 'react';
import Map from './Map';

function Location(props) {
    if ( ! props.latitude || ! props.longitude) {
        return null;
    }

    return (
        <div className="event-map"><Map name={ props.name.en } zoom={ 13 } position={{ lat: props.latitude, lng: props.longitude }} /></div>
    );
}

export default Location;