import axios from 'axios';

axios.defaults.headers.common = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

const apiBaseUrl = process.env.REACT_APP_API_URI + '/api/';

const currentWeather = () => {
    return axios.get(apiBaseUrl + 'weather/current');
}

const weatherForecast = () => {
    return axios.get(apiBaseUrl + 'weather/forecast');
}

const futureEvents = () => {
    return axios.get(apiBaseUrl + 'events/future');
}

const allPois = () => {
    return axios.get(apiBaseUrl + 'pois');
}

const roomTypes = () => {
    return axios.get(apiBaseUrl + 'rooms/types');
}

const getHotelInfo = () => {
    return axios.get(apiBaseUrl + 'info');
}

const getStaticPage = (slug) => {
    return axios.get(apiBaseUrl + 'static/' + slug);
}

const makeReservation = (data) => {
    return axios.post(apiBaseUrl + 'booking', data);
}

const login = (data) => {
    return axios.post(apiBaseUrl + 'login', data);
}

export { currentWeather, weatherForecast, futureEvents, allPois, roomTypes, getHotelInfo, getStaticPage, makeReservation, login };