import React from 'react';
import Header from '../components/Header';

import ImageHotel1 from '../images/gallery/hotel-1.jpg';
import ImageHotel2 from '../images/gallery/hotel-2.jpg';

import ImageRestaurant1 from '../images/gallery/restaurant-1.jpg';
import ImageRestaurant2 from '../images/gallery/restaurant-2.jpg';
import ImageRestaurant3 from '../images/gallery/restaurant-3.jpg';

import ImageConference1 from '../images/gallery/conference-1.jpg';
// import ImageConference2 from '../images/gallery/conference-2.jpg';
// import ImageConference3 from '../images/gallery/conference-3.jpg';

import ImageLift1 from '../images/gallery/lift-1.jpg';

import ImageApartment1 from '../images/gallery/apartment-1.jpg';
import ImageApartment2 from '../images/gallery/apartment-2.jpg';
import ImageApartment3 from '../images/gallery/apartment-3.jpg';

import ImageStudio1 from '../images/gallery/studio-1.jpg';
import ImageStudio2 from '../images/gallery/studio-2.jpg';
import ImageStudio3 from '../images/gallery/studio-3.jpg';

import ImageDouble1 from '../images/gallery/double-1.jpg';
import ImageDouble2 from '../images/gallery/double-2.jpg';
import ImageDouble3 from '../images/gallery/double-3.jpg';

import ImageTwin1 from '../images/gallery/twin-1.jpg';
import ImageTwin2 from '../images/gallery/twin-2.jpg';

import ImageSingle1 from '../images/gallery/single-1.jpg';

import ImageRooms1 from '../images/gallery/rooms-1.jpg';
// import ImageRooms2 from '../images/gallery/rooms-2.jpg';
// import ImageRooms3 from '../images/gallery/rooms-3.jpg';
// import ImageRooms4 from '../images/gallery/rooms-4.jpg';

import ImageCleaning1 from '../images/gallery/cleaning-1.jpg';

function Gallery() {
    return (
        <>
            <Header backTo="/" />
            <h1 className="page-title">Central Gallery</h1>
            <div className="gallery-wrapper">
                <img src={ ImageHotel1 } className="gallery-image" loading="lazy" alt="Hotel Central" />
                <img src={ ImageHotel2 } className="gallery-image" loading="lazy" alt="Hotel Central" />

                <img src={ ImageRestaurant1 } className="gallery-image" loading="lazy" alt="Restaurant" />
                <img src={ ImageRestaurant2 } className="gallery-image" loading="lazy" alt="Restaurant" />
                <img src={ ImageRestaurant3 } className="gallery-image" loading="lazy" alt="Restaurant" />

                <img src={ ImageConference1 } className="gallery-image" loading="lazy" alt="Conference" />
                {/* <img src={ ImageConference2 } className="gallery-image" loading="lazy" alt="Conference" />
                <img src={ ImageConference3 } className="gallery-image" loading="lazy" alt="Conference" /> */}

                <img src={ ImageLift1 } className="gallery-image" loading="lazy" alt="Lift" />

                <img src={ ImageApartment1 } className="gallery-image" loading="lazy" alt="Apartment" />
                <img src={ ImageApartment2 } className="gallery-image" loading="lazy" alt="Apartment" />
                <img src={ ImageApartment3 } className="gallery-image" loading="lazy" alt="Apartment" />

                <img src={ ImageStudio1 } className="gallery-image" loading="lazy" alt="Studio" />
                <img src={ ImageStudio2 } className="gallery-image" loading="lazy" alt="Studio" />
                <img src={ ImageStudio3 } className="gallery-image" loading="lazy" alt="Studio" />

                <img src={ ImageDouble1 } className="gallery-image" loading="lazy" alt="Double" />
                <img src={ ImageDouble2 } className="gallery-image" loading="lazy" alt="Double" />
                <img src={ ImageDouble3 } className="gallery-image" loading="lazy" alt="Double" />

                <img src={ ImageTwin1 } className="gallery-image" loading="lazy" alt="Twin" />
                <img src={ ImageTwin2 } className="gallery-image" loading="lazy" alt="Twin" />

                <img src={ ImageSingle1 } className="gallery-image" loading="lazy" alt="Single" />

                <img src={ ImageRooms1 } className="gallery-image" loading="lazy" alt="Rooms" />
                {/* <img src={ ImageRooms2 } className="gallery-image" loading="lazy" alt="Rooms" />
                <img src={ ImageRooms3 } className="gallery-image" loading="lazy" alt="Rooms" />
                <img src={ ImageRooms4 } className="gallery-image" loading="lazy" alt="Rooms" /> */}

                <img src={ ImageCleaning1 } className="gallery-image" loading="lazy" alt="Cleaning" />
            </div>
        </>
    );
}

export default Gallery;