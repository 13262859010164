import React from 'react';
import { connect } from 'react-redux';
import Spinner from '../components/Spinner';
import Header from '../components/Header';

import { fetchStaticPage } from '../actions/staticPage';



class StaticPage extends React.Component {
    componentDidMount() {
        const { fetchPage, slug } = this.props;

        fetchPage(slug);
    }

    render() {
        const { data, loading } = this.props;

        if (loading) {
            return (
                <>
                    <Header backTo="//" />
                    <Spinner />
                </>
            );
        }

        if (data.length === 0) {
            return null;
        }

        return (
            <>
                <Header backTo="/" />
                <h1 className="page-title">{ data.name.en }</h1>
                { data.image && <div className="data-image"><img src={ data.image } alt={ data.name.en } /></div> }
                <div className="page-content mt-4 mb-8" dangerouslySetInnerHTML={{ __html: data.content.en }}></div>
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({
    data: state.staticPage.data,
    loading: state.staticPage.loading,
    error: state.staticPage.error
});

const mapDispatchToProps = (dispatch) => ({
    fetchPage: (slug) => {
        dispatch(fetchStaticPage(slug))
    }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StaticPage);