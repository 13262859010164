import React from 'react';
import { connect } from 'react-redux';
import Spinner from '../components/Spinner';
import Header from '../components/Header';
import Address from '../components/Address';
import Location from '../components/Location';

import format from 'date-fns/format';

import { fetchFutureEvents } from '../actions/events';

class Event extends React.Component {
    componentDidMount() {
        const { fetchEvents } = this.props;

        fetchEvents();
    }

    render() {
        const { event, loading } = this.props;

        if (loading) {
            return (
                <>
                    <Header backTo="/events/" />
                    <Spinner />
                </>
            );
        }

        if ( ! event) {
            return null;
        }

        return (
            <>
                <Header backTo="/events/" />
                <h1 className="page-title">{ event.name.en }</h1>
                <div className="event-dates flex items-center mt-4">
                    <div className="event-date-starts w-1/2 bg-brand-light py-2 px-4">
                        <div className="date__label text-xs text-brand-lightest pb-1">Starts</div>
                        <div className="date__date font-bold text-lg text-white">{ format(event.ends_at.date, 'ddd, MMM DD. HH:mm') }</div>
                    </div>
                    <div className="event-date-ends w-1/2 bg-brand py-2 px-4">
                        <div className="date__label text-xs text-brand-lightest pb-1">Ends</div>
                        <div className="date__date font-bold text-lg text-white">{ format(event.ends_at.date, 'ddd, MMM DD. HH:mm') }</div>
                    </div>
                </div>
                { event.image && <div className="event-image"><img src={ event.image } alt={ event.name.en } /></div> }
                <div className="page-content mt-4 mb-8" dangerouslySetInnerHTML={{ __html: event.content.en }}></div>
                { event.url && <div className="event-url"><a href={ event.url } target="_blank" rel="noopener noreferrer">{ event.url }</a></div> }
                <Address { ...event } />
                <Location { ...event } />
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({
    event: state.events.future.find(event => event.id === parseInt(props.match.params.id, 10)),
    loading: state.events.loading,
    error: state.events.error
});

const mapDispatchToProps = (dispatch) => ({
    fetchEvents: () => {
        dispatch(fetchFutureEvents())
    }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Event);