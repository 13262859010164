import { RECEIVE_ALL_POIS, POIS_FETCHING_FAILED, START_FETCHING_POIS } from '../actions/pois';

export const pois = (state = {
    items: [],
    loading: false,
    error: false
}, action) => {
    switch (action.type) {
        case RECEIVE_ALL_POIS:
            return Object.assign({}, state, {
                items: action.items,
                loading: false,
                error: false
            });
        case POIS_FETCHING_FAILED:
            return Object.assign({}, state, {
                items: [],
                error: true,
                loading: false
            });
        case START_FETCHING_POIS:
            return Object.assign({}, state, {
                loading: true
            });
        default:
            return Object.assign({}, state);
    }
}