import { combineReducers } from 'redux';
import { weather } from './weather';
import { events } from './events';
import { pois } from './pois';
import { rooms } from './rooms';
import { info } from './info';
import { staticPage } from './staticPage';
import { reservations } from './reservations';
import { login } from './login';

const centralSbReducers = combineReducers({
    weather,
    events,
    pois,
    rooms,
    info,
    staticPage,
    reservations,
    login
});

export default centralSbReducers;