import React from 'react';

function Address(props) {
    if ( ! props.address) {
        return null;
    }

    return (
        <div className="event-address bg-brand p-4">
            <h3 className="event-address-label text-brand-lighter text-xs font-normal mb-2">
                <svg className="mr-2" style={{ width: '10px', height: '10px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"></path></svg>
                Address
            </h3>
            <div className="event-address-text text-white" dangerouslySetInnerHTML={{ __html: props.address }}></div>
        </div>
    );
}

export default Address;