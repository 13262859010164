import { futureEvents } from '../helpers/api';

export const START_FETCHING_EVENTS = 'START_FETCHING_EVENTS';
function startFetchingEvents() {
    return {
        type: START_FETCHING_EVENTS,
        loading: true
    }
}

export const EVENTS_FETCHING_FAILED = 'EVENTS_FETCHING_FAILED';
function eventsFetchingFailed() {
    return {
        type: EVENTS_FETCHING_FAILED
    }
}

export const RECEIVE_FUTURE_EVENTS = 'RECEIVE_FUTURE_EVENTS';
function receiveFutureEvents(events) {
    return {
        type: RECEIVE_FUTURE_EVENTS,
        future: events
    }
}

export function fetchFutureEvents() {
    return (dispatch, getState) => {
        dispatch(startFetchingEvents());

        const { events } = getState();

        if (events.future.length > 0) {
            return dispatch(receiveFutureEvents(events.future));
        }

        return futureEvents().then((response) => {
            if (response.status !== 200) {
                return dispatch(eventsFetchingFailed());
            }

            return dispatch(receiveFutureEvents(response.data.data));
        });
    }
}