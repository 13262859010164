import React from 'react';

function Spinner(props) {
    const { type } = props;
    const classes = type === 'small' ? 'loader loader--small' : 'loader';

    return (
        <div className={ classes }>Loading...</div>
    );
}

export default Spinner;