import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

import Home from './pages/Home';
import Rooms from './pages/Rooms';
import Room from './pages/Room';
import Events from './pages/Events';
import Event from './pages/Event';
import Weather from './pages/Weather';
import Restaurant from './pages/Restaurant';
import Pois from './pages/Pois';
import Poi from './pages/Poi';
import HotelInfo from './pages/HotelInfo';
import StaticPage from './pages/StaticPage';
import Gallery from './pages/Gallery';
import Activate from './pages/Activate';
import Concierge from './pages/Concierge';
import Wallet from './pages/Wallet';

const AppRouter = () => (
    <Router>
        <AnimatedSwitch
            atEnter={{ offset: -100, opacity: 0 }}
            atLeave={{ offset: -100, opacity: 0 }}
            atActive={{ offset: 0, opacity: 1 }}
            mapStyles={(styles) => ({
              transform: `translateX(${styles.offset}%)`
            })}
            className="switch-wrapper"
        >
            <Route path="/" exact component={ Home } />
            <Route path="/activate" exact component={ Activate } />
            <Route path="/info/" exact component={ HotelInfo } />
            <Route path="/conference/" exact render={ (props) => <StaticPage { ...props } slug="conference" /> } />
            <Route path="/concierge/" exact component={ Concierge } />
            <Route path="/wallet/" exact component={ Wallet } />
            <Route path="/gallery/" exact component={ Gallery } />
            <Route path="/rooms/" exact component={ Rooms } />
            <Route path="/rooms/:id" exact component={ Room } />
            <Route path="/events/" exact component={ Events } />
            <Route path="/events/:id" component={ Event } />
            <Route path="/weather/" component={ Weather } />
            <Route path="/guide/" exact component={ Pois } />
            <Route path="/guide/:id" component={ Poi } />
            <Route path="/restaurant" component={ Restaurant } />
        </AnimatedSwitch>
    </Router>
);

export default AppRouter;