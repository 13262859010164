import { START_LOGIN, LOGIN_FAILED, LOGIN_SUCCEEDED } from '../actions/login';

export const login = (state = {
    loading: false,
    errors: [],
}, action) => {
    switch (action.type) {
        case START_LOGIN:
            return Object.assign({}, state, {
                loading: true,
                errors: [],
            });
        case LOGIN_FAILED:
            return Object.assign({}, state, {
                loading: false,
                errors: action.errors,
            });
        case LOGIN_SUCCEEDED:
            return Object.assign({}, state, {
                loading: false,
                errors: [],
            });
        default:
            return Object.assign({}, state);
    }
}