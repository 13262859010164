import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo.png';

function CenteredLogo() {
    return (
        <div className="flex content-center justify-center py-5">
            <Link to="/">
                <img src={ logo } alt="Hotel Central SB" />
            </Link>
        </div>
    );
}

export default CenteredLogo;