import React from 'react';
import { format, addDays } from 'date-fns'
import { connect } from 'react-redux';
import Spinner from './Spinner';
import { reserveARoom } from '../actions/reservations';

function Button(props) {
    const { loading, success } = props;

    return (
        <button className="reserve-button" type="submit" disabled={ loading || success }>
            { loading ? <Spinner type="small" /> : "Book a room" }
        </button>
    );
}

function Errors(props) {
    const { errors } = props;

    if ( ! errors || errors.length === 0) {
        return null;
    }

    const messages = Object.keys(errors).map((key, index) => {
        return errors[key];
    });

    return (
        <div className="reservation-errors">
            <svg className="reservation__icon" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z"></path></svg>
            <ul className="reservation-errors__messages">
            {
                 messages.map((error, key) => {
                    return (
                        <li key={ key }>{ error }</li>
                    );
                })
            }
            </ul>
        </div>
    );
}

class InlineReservationForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            roomId: props.roomId,
            startDate: format(addDays(new Date(), 1), "YYYY-MM-DD"),
            endDate: format(addDays(new Date(), 8), "YYYY-MM-DD"),
            email: '',
            name: '',
            rooms: 1,
            adults: 1,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { book } = this.props;

        book({
            'room_type_id': this.state.roomId,
            'check_in': this.state.startDate,
            'check_out': this.state.endDate,
            'name': this.state.name,
            'email': this.state.email,
            'adults': this.state.adults,
            'rooms': this.state.rooms
        });
    }

    render() {
        const { loading, success, errors, message } = this.props;

        if (success) {
            return (
                <div className="reservation-success">
                    <svg className="reservation__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z"></path></svg>
                    <div className="reservation-success__message">{ message }</div>
                </div>
            );
        }

        return (
            <>
                <form onSubmit={ this.handleSubmit } className="reservation-form">
                    <div className="form-row">
                        <div className="form-field">
                            <label htmlFor="startDate">From</label>
                            <input required id="startDate" onChange={ this.handleInputChange } name="startDate" type="date" value={ this.state.startDate } />
                        </div>
                        <div className="form-field">
                            <label htmlFor="endDate">To</label>
                            <input required id="endDate" onChange={ this.handleInputChange } name="endDate" type="date" value={ this.state.endDate } />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-field">
                            <label htmlFor="name">Name</label>
                            <input  id="name" onChange={ this.handleInputChange } name="name" type="text" value={ this.state.name } />
                        </div>
                        <div className="form-field">
                            <label htmlFor="email">Email</label>
                            <input  id="email" onChange={ this.handleInputChange } name="email" type="email" value={ this.state.email } />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-field">
                            <label htmlFor="adults">Number of adults</label>
                            <input  id="adults" onChange={ this.handleInputChange } name="adults" type="text" value={ this.state.adults } />
                        </div>
                        <div className="form-field">
                            <label htmlFor="rooms">Number of rooms</label>
                            <input  id="rooms" onChange={ this.handleInputChange } name="rooms" type="text" value={ this.state.rooms } />
                        </div>
                    </div>
                    <Errors errors={ errors } />
                    <div className="button-container">
                        <Button loading={ loading } success={ success } />
                    </div>
                </form>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.reservations.loading,
    errors: state.reservations.errors,
    success: state.reservations.success,
    message: state.reservations.message
});

const mapDispatchToProps = (dispatch) => ({
    book: (data) => {
        dispatch(reserveARoom(data))
    }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InlineReservationForm);