import { RECEIVE_HOTEL_INFO, HOTEL_INFO_FETCHING_FAILED, START_FETCHING_HOTEL_INFO } from '../actions/info';

export const info = (state = {
    data: [],
    loading: false,
    error: false
}, action) => {
    switch (action.type) {
        case RECEIVE_HOTEL_INFO:
            return Object.assign({}, state, {
                data: action.data,
                loading: false,
                error: false
            });
        case HOTEL_INFO_FETCHING_FAILED:
            return Object.assign({}, state, {
                data: [],
                error: true,
                loading: false
            });
        case START_FETCHING_HOTEL_INFO:
            return Object.assign({}, state, {
                loading: true
            });
        default:
            return Object.assign({}, state);
    }
}