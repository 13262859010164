import { allPois } from '../helpers/api';

export const START_FETCHING_POIS = 'START_FETCHING_POIS';
function startFetchingPois() {
    return {
        type: START_FETCHING_POIS,
        loading: true
    }
}

export const POIS_FETCHING_FAILED = 'POIS_FETCHING_FAILED';
function poisFetchingFailed() {
    return {
        type: POIS_FETCHING_FAILED
    }
}

export const RECEIVE_ALL_POIS = 'RECEIVE_ALL_POIS';
function receiveAllPois(items) {
    return {
        type: RECEIVE_ALL_POIS,
        items
    }
}

export function fetchAllPois() {
    return (dispatch, getState) => {
        dispatch(startFetchingPois());

        const { pois } = getState();

        if (pois.items.length > 0) {
            return dispatch(receiveAllPois(pois.items));
        }

        return allPois().then((response) => {
            if (response.status !== 200) {
                return dispatch(poisFetchingFailed());
            }

            return dispatch(receiveAllPois(response.data.data));
        });
    }
}